import React, { useState, useEffect } from "react";
import firebase from "./firebaseConfig"; // Adjust the path to your firebaseConfig file
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import "./App.css";
import StepThree from "./StepThree";
import Loader from "./Loader";
import "./Loader.css";
import toast from "react-hot-toast";
import axios from "axios";

const StepForm = () => {
  const [formattedData, setFormattedData] = useState("");
  const [firebaseIdToken, setFirebaseIdToken] = useState();
  const [step, setStep] = useState(0);
  const [formResponseId, setFormResponseId] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState({
    username: "",
    mobile: "",
  });
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const defaultisd = window.location.href.includes("/us") ? "+1" : "+91";
  const [isd, setIsd] = useState(defaultisd);
  const appCode = "HMPL";
  const queryString = window.location.search; // Get the query string from the URL
  const urlParams = new URLSearchParams(queryString);
  const { slug } = useParams();
  const location = useLocation();
  const urlQuery = location.search;

  const mmhApiUrl = "https://api.makemyhouse.com";

  const handleCountryCodeChange = (code) => {
    setIsd(code);
    console.log("selected country code", code);
  };
  const handleNext2 = () => {
    setStep(step + 1);
  };
  useEffect(() => {
    console.log("step", step);
  }, [step]);
  const handleNext = async () => {
    if (step === 1) {
        setIsLoading(true);
        // Initialize reCAPTCHA verifier
        const verifier = new firebase.auth.RecaptchaVerifier(
          "recaptcha-container",
          {
            size: "invisible",
            callback: (response) => {
              // reCAPTCHA resolved, continue with sending OTP
            },
            "error-callback": (error) => {
              // Handle the error here
              console.error("Error initializing reCAPTCHA verifier:", error);
              return;
            },
          }
        );

        // Call Send Otp Api Required Fields: isd,mobile,appcode

        const sendOtpUrl = `${mmhApiUrl}/customer/me/loginotp`;

                try {
                  let formData2 = new FormData();
                  formData2.append("mobile", userData.mobile);
                  formData2.append("isd", isd.replace("+", ""));
                  formData2.append("appcode", appCode);

                  const response = await axios.post(sendOtpUrl, formData2);
              
                  console.log("SendOtpResponse: ", response);
              
                  if (response.status === 200) {
                    setStep(step + 1);
                    setIsLoading(false);
                  } else if (response.status === 403) {
                    setIsLoading(false);
                    alert(response.data.msg || 'Access forbidden: You do not have permission to access this resource.');
                  } else {
                    setIsLoading(false);
                    alert("Something Went Wrong!");
                    return;
                  }
                } catch (error) {
                  setIsLoading(false);
                  if (error.response) {
                    // Server responded with a status code out of the range of 2xx
                    if (error.response.status === 403) {
                      alert(error.response.data.msg || 'Access forbidden: You do not have permission to access this resource.');
                    } else {
                      alert(`HTTP error! Status: ${error.response.status}`);
                    }
                  } else if (error.request) {
                    // Request was made but no response received
                    alert('No response received from the server.');
                  } else {
                    // Something else happened while setting up the request
                    alert('Error: ' + error.message);
                  }
                }
    } else {
      // Handle other steps
    }
  };

 const handleVerifyOtp = async () => {
   setIsLoading(true);
   // Call Verify OTP Api Required Fields : otp, mobile, appcode, isd
   const verifyOtpUrl = `${mmhApiUrl}/customer/me/veryfyotp`;

   // Create a new FormData object
   let formData = new FormData();
   formData.append("mobile", userData.mobile);
   formData.append("isd", isd.replace("+", ""));
   formData.append("appcode", appCode);
   formData.append("otp", otp);

   formData.append("name", userData?.username);
   formData.append("duplicate", 1);
   formData.append("leadsource", "Floor Planner");
   formData.append("leadstatus",  "Hot",);
   formData.append("typestatus", "not contacted");
   formData.append("requirement", formattedData);


   try {
     const response2 = await axios.post(verifyOtpUrl, formData);

     if (response2.status === 200) {
       // For btnUrl Only No Use in app
       const isUS2 = window.location.href.includes("/us");
       const finalUrl2 = isUS2
         ? `/us/response/checkout/${slug}${urlQuery}&isd=${isd}&mobile=${userData?.mobile}`
         : `/response/checkout/${slug}${urlQuery}&isd=${isd}&mobile=${userData?.mobile}`;
       // End

       // API For CRM Entry
       var name = userData.username ? userData.username : userData.mobile;

       const apiUrl = `https://forms-api.makemyhouse.com/updateResponse/${formResponseId}`;
       // const apiUrl = `http://localhost:4000/updateResponse/${formResponseId}`;

       try {
         const response = await axios.post(
           apiUrl,
           {
             mobileNumber: userData.mobile,
             name: name,
             isd: isd,
             btnUrl: finalUrl2,
           },
           {
             headers: {
               "Content-Type": "application/json",
               Authorization: `Bearer vcju67w54b vuttf67t63byua213bk`,
             },
           }
         );

         const data = response.data;
         console.log("API Response from get updatedResponse:", data);
         setIsLoading(false);

         // Handle success
         if (data.flag) {
           // API call was successful
           localStorage.removeItem('timerLastRunDate');
           localStorage.removeItem('timerLastRunTime');
           localStorage.removeItem('RemainingTimerTime');
           localStorage.removeItem('offerCookie');
           localStorage.removeItem('isRedirected');
           setStep(step + 1);
           console.log("Document updated successfully");
         } else {
           setStep(step + 1);
           // API call failed
           console.log("Error updating document:", data.message);
         }
       } catch (error) {
         setStep(step + 1);
         console.log("Error for fetching response API: ", error);
         // Handle network errors or exceptions
       }
     } else if (response2.status === 404) {
       setIsLoading(false);
       alert("Error: wrong otp entered");
       return;
     } else {
       setIsLoading(false);
       throw new Error("Network response was not ok");
     }
   } catch (error) {
     setIsLoading(false);
      if (error.response) {
        // Server responded with a status code out of the range of 2xx
        if (error.response.status === 404) {
          alert(error.response.data.msg || 'Something went wrong please try again later!');
        } else {
          alert(`HTTP error! Status: ${error.response.status}`);
        }
      } else if (error.request) {
        // Request was made but no response received
        alert('No response received from the server.');
      } else {
        // Something else happened while setting up the request
        alert('Error: ' + error.message);
      }
   }
 };

  const navigate = useNavigate();

  useEffect(() => {
    const previousPath = localStorage?.getItem("isBackCheckoutUrl");

    const modifiedUrl = previousPath;
    console.log("is coming back!");
    const timer = setTimeout(() => {
      if (
        modifiedUrl &&
        modifiedUrl?.includes(
          "/response/checkout/form_one_64e59ece81a786fc0e09fa8a"
        )
      ) {
        // window.history.pushState(window.location.href,'login url')
        const currentDate = new Date().toLocaleDateString();
        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        const currentTime = new Date().getTime();
        const lastRunTime =
          parseInt(localStorage.getItem("timerLastRunTime")) || 0;
        const TEN_MINUTES = 10 * 60 * 1000; // 10 minutes in milliseconds
        const RemainingTime = localStorage.getItem("RemainingTimerTime");

        if (
          localStorage.getItem("isRedirected") == 1 &&
          currentDate >= lastRunDate
        ) {
          if (RemainingTime > 0 || currentDate === lastRunDate) {
            localStorage.setItem("isRedirected", 1);
            // let offcookie =
            //   localStorage.getItem("offerCookie") == "1" ? "0" : "1";
            // localStorage.setItem("offerCookie", offcookie);
            navigate(modifiedUrl);
          } else {
            localStorage.setItem("isRedirected", 0);
            localStorage.setItem("offerCookie", "1");
          }
          console.log(
            "isRedirected.....2",
            localStorage.getItem("isRedirected")
          );
        } else {
          console.log(
            "isRedirected.....3",
            localStorage.getItem("isRedirected")
          );

          localStorage.setItem("isRedirected", 1);
          navigate(modifiedUrl);
        }
      } else {
        const currentDate = new Date().toLocaleDateString();
        const lastRunDate = localStorage.getItem("timerLastRunDate") || "";
        localStorage.setItem("isRedirected", 0);
        console.log("isRedirected.....4", localStorage.getItem("isRedirected"));

        if (
          localStorage.getItem("isRedirected") == 1 &&
          currentDate > lastRunDate
        ) {
          localStorage.setItem("isRedirected", 0);
          console.log(
            "isRedirected.....5",
            localStorage.getItem("isRedirected")
          );
        }
      }
    }, 50);
    return () => clearTimeout(timer);
  }, [navigate, window.location.href]);

  useEffect(() => {
    if (step === 3) {
      const isUS = window.location.href.includes("/us");
      const finalUrl = isUS
        ? `/us/response/checkout/${slug}${urlQuery}&isd=${isd}&mobile=${userData?.mobile}`
        : `/response/checkout/${slug}${urlQuery}&isd=${isd}&mobile=${userData?.mobile}`;
      navigate(finalUrl);
      // window.location.href = finalUrl;
    }
  }, [step]);

  useEffect(() => {
    const fetchResponseData = async () => {
      const formId = urlParams.get("Fx");
      const responseId = urlParams.get("Rx");
      if (responseId) {
        setFormResponseId(responseId);
        try {
          const response = await fetch(
            `https://forms-api.makemyhouse.com/response?q=${responseId}`
          );
          if (!response.ok) {
            throw new Error("Network response was not ok.");
          }
          const data = await response.json();
          console.log("response form data :", data.data);
          setFormattedData(data.data);
        } catch (error) {
          console.error("Fetch error:", error);
        }
      }
    };

    fetchResponseData();
  }, []);

  

  return (
    <div className="step-form-container">
      <div className="step-form">
        {step === 0 && <Loader handleNext2={handleNext2} />}
        {step === 1 && (
          <StepOne
            handleCountryCodeChange={handleCountryCodeChange}
            userData={userData}
            setUserData={setUserData}
            handleNext={handleNext}
            isLoading={isLoading}
          />
        )}
        {step === 2 && (
          <StepTwo
            otp={otp}
            setOtp={setOtp}
            handleVerifyOtp={handleVerifyOtp}
            isLoading={isLoading}
          />
        )}
        {/* {step === 3 && <StepThree slug={slug} isd={isd} mobile={userData.mobile} responseId={formResponseId} />} */}
      </div>
    </div>
  );
};

export default StepForm;
